<template>
  <div>
    <table class="tbl_row mt10">
      <colgroup>
        <col width="150"><col>
        <col width="150"><col>
      </colgroup>
      <tbody>
        <tr v-for="(vo, idx) in closeInfoListReal2" :key="idx">
          <th>{{ vo.aCdNm }}</th>
          <td v-show="vo.bVal === undefined" colspan="3" v-html="vo.aVal"></td>
          <td v-show="vo.bVal !== undefined" v-html="vo.aVal"></td>
          <th v-show="vo.bVal !== undefined">{{ vo.bCdNm }}</th>
          <td v-show="vo.bVal !== undefined" v-html="vo.bVal"></td>
        </tr>
        <!-- <tr v-for="(vo, idx) in closeInfoList" :key="idx">
          <th>{{ vo.aclosInfNm }}</th>
          <td v-show="vo.bclosInfNm === undefined" colspan="3">{{ vo.aclosInfVar }}</td>
          <td v-show="vo.bclosInfNm !== undefined">{{ vo.aclosInfVar }}</td>
          <th v-show="vo.bclosInfNm !== undefined">{{ vo.bclosInfNm }}</th>
          <td v-show="vo.bclosInfNm !== undefined">{{ vo.bclosInfVar }}</td>
        </tr> -->
        <tr v-if="parentInfo && parentInfo.menuCd === '02' && ['KR', 'JP'].indexOf(parentInfo.polCtrCd) > -1">
          <th>{{ $t('msg.ONEX010P030.020') }}</th><!-- 반입지 / 담당자 -->
          <td v-if="parentInfo.polShtmCd !== '02'" colspan="3">{{ vesselDetail.cgoPic }}</td>
          <td v-if="parentInfo.polShtmCd === '02'" colspan="3">
            <p>{{ parentInfo.txtCfsPolTrmlInfo }}</p>
            <font color="red" id="cfsred">{{ $t('msg.CSBK100.087') }}</font>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo'

export default {
  name: 'CloseInfo',
  components: {
  },
  props: {
    parentInfo: {
      type: Object,
      default () {
        return {
          polCtrCd: '',
          polPortCd: '',
          polTrmlCd: '',
          cgoPic: '',
          bkgDocCls: '',
          srPic: '',
          bkgMfCls: '',
          cgoCls: '',
          cfsCls: '',
          rteCd: '',
          menuCd: '',
          polEtaDt: '', // 선적항 도착 예정 날짜시간(Arrival)
          polEtdDt: '', // 선적항 출발 예정 날짜시간(Departure)
          polShtmCd: '',
          mrnNo: '',
          txtCfsPolTrmlInfo: '' // CFS 반입지 / 담당자
        }
      }
    },
    vesselInfo: {
      type: Object,
      default: null
    },
    kmdVslCheck: {
      type: String,
      default: 'N'
    }
  },
  data () {
    return {
      commonCodes: [],
      closeInfoData: [],
      closeInfoList: [],
      closeInfoListReal: [],
      closeInfoListReal2: [],
      valueFromICC: [],
      vesselDetail: {},
      cyOpen: '',
      cyCut: '',
      isShowPolTrmlInfo: 'Y' // 반입지/담당자를 보여줄지 여부
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    parentInfo () {
      if (this.parentInfo !== null) {
        this.setVesselInfo()
        // this.initPage()
      }
    }
  },
  created () {
    if (this.parentInfo !== null) {
      this.parentInfo.polPortCd = this.parentInfo.polPortCd || this.parentInfo.pol
      this.parentInfo.polTrmlCd = this.parentInfo.polTrmlCd || this.parentInfo.polTml
      setTimeout(() => {
        this.setVesselInfo()
        // this.initPage()
      }, 500)
    }
  },
  methods: {
    async setVesselInfo () {
      // this.valueFromICC = [
      //   { cd: '08', val: this.parentInfo.cgoPic }, // 반입지/담당자
      //   { cd: '09', val: this.parentInfo.bkgDocCls }, // 서류마감
      //   { cd: '10', val: this.vesselInfo.cllPic }, // 모선마감 담당자
      //   { cd: '11', val: this.parentInfo.bkgDocCls }, // VGM 마감
      //   { cd: '12', val: this.vesselInfo.srPic }, // S/R 마감 담당자
      //   { cd: '13', val: this.parentInfo.bkgMfCls }, // EDI 신고마감
      //   { cd: '14', val: this.vesselInfo.ediPic }, // 세관/위험물신고 담당자
      //   { cd: '15', val: this.parentInfo.bkgCgoCls }, // CY 반입마감
      //   { cd: '16', val: this.parentInfo.cfsCls }, // CFS 반입마감
      //   { cd: '17', val: this.parentInfo.polTml }, // Terminal
      //   { cd: '18', val: this.vesselInfo.trmlVslCd + '/' + this.vesselInfo.trmlVoyNo }, // Terminal VSL/VOY
      //   { cd: '19', val: this.vesselInfo.mrnNo }, // MRN
      //   { cd: '20', val: this.vesselInfo.clsgNo } // Call Sign
      // ]

      // [수출 관련]
      // 수출업무 담당 가져오기
      if (this.vesselInfo === null && this.$ekmtcCommon.isNotEmpty(this.parentInfo.vslCd)) {
        await vslScheduleInfo.getVslScheduleInfoDetail(this.parentInfo).then(response => {
          if (response.status === 200) {
            const data = response.data.vesselInfoDetail
            this.vesselDetail = { ...data }
          }
        })
      } else {
        this.vesselDetail = { ...this.vesselInfo }
      }
      // 마감정보 ICC(Closing Time management - CY OPEN)
      this.cyOpen = ''
      this.cyCut = ''
      await vslScheduleInfo.getCloseInfoCYOpen(this.parentInfo).then(res => {
        const closeInfoCYOpen = res.data.closeInfoCYOpen
        if (closeInfoCYOpen.length > 0) {
          if (!this.$ekmtcCommon.isEmpty(closeInfoCYOpen[0].polCyOpenDt)) {
            //this.cyOpen = this.$ekmtcCommon.changeDatePattern(closeInfoCYOpen[0].polCyOpenDt, '.')
            this.cyOpen = this.changeDateTime2Display(closeInfoCYOpen[0].polCyOpenDt, this.auth.serviceLang)
          }
          if (!this.$ekmtcCommon.isEmpty(closeInfoCYOpen[0].polCyCutDt)) {
            //this.cyCut = this.$ekmtcCommon.changeDatePattern(closeInfoCYOpen[0].polCyCutDt, '.')
            this.cyCut = this.changeDateTime2Display(closeInfoCYOpen[0].polCyCutDt, this.auth.serviceLang)
          }
        }
      })
      const params = {
        lang: localStorage.getItem('service_lang') === 'KOR' ? 'KO' : 'EN'
      }
      await vslScheduleInfo.getCloseInfoCodes(params).then(res => {
        const TH = this
        TH.valueFromICC = res.data.closeInfoCode

        let cllPic = ''
        let srPic = ''
        let ediPic = ''

        const cgoPic = TH.$ekmtcCommon.isNotEmpty(TH.parentInfo.cgoPic) ? TH.parentInfo.cgoPic : ''
        const bkgDocCls = TH.$ekmtcCommon.isNotEmpty(TH.parentInfo.bkgDocCls) ? TH.parentInfo.bkgDocCls : ''
        const vgmDocCls = TH.$ekmtcCommon.isNotEmpty(TH.parentInfo.vgmDocCls) ? TH.parentInfo.vgmDocCls : this.$ekmtcCommon.isNotEmpty(this.vesselDetail.vgmCgoCls) ? this.changeDateTime2Display(this.vesselDetail.vgmCgoCls, this.auth.serviceLang) : ''
        if (TH.parentInfo.polCtrCd === 'KR') {
          cllPic = TH.$ekmtcCommon.isNotEmpty(this.vesselDetail.cllPic) ? this.vesselDetail.cllPic + ' DOCCLL@EKMTC.COM' : ''
          srPic = TH.$ekmtcCommon.isNotEmpty(this.vesselDetail.srPic) ? this.vesselDetail.srPic + ' DOCSR@EKMTC.COM' : ''
          ediPic = TH.$ekmtcCommon.isNotEmpty(this.vesselDetail.ediPic) ? this.vesselDetail.ediPic + ' DOCEDI@EKMTC.COM' : ''
        } else {
          cllPic = TH.$ekmtcCommon.isNotEmpty(this.vesselDetail.cllPic) ? this.vesselDetail.cllPic : ''
          srPic = TH.$ekmtcCommon.isNotEmpty(this.vesselDetail.srPic) ? this.vesselDetail.srPic : ''
          ediPic = TH.$ekmtcCommon.isNotEmpty(this.vesselDetail.ediPic) ? this.vesselDetail.ediPic : ''
        }

        const bkgMfCls = TH.$ekmtcCommon.isNotEmpty(TH.parentInfo.bkgMfCls) ? TH.parentInfo.bkgMfCls : ''
        const bkgCgoCls = TH.$ekmtcCommon.isNotEmpty(TH.parentInfo.bkgCgoCls) ? TH.parentInfo.bkgCgoCls : ''
        const cfsCls = TH.$ekmtcCommon.isNotEmpty(TH.parentInfo.cfsCls) ? TH.parentInfo.cfsCls : ''
        const mrnNo = TH.$ekmtcCommon.isNotEmpty(TH.parentInfo.mrnNo) ? TH.parentInfo.mrnNo : this.$ekmtcCommon.isNotEmpty(TH.vesselInfo) ? this.$ekmtcCommon.isNotEmpty(TH.vesselInfo.mrnNo) ? TH.vesselInfo.mrnNo : '' : ''
        const callSign = TH.$ekmtcCommon.isNotEmpty(TH.parentInfo.callSign) ? TH.parentInfo.callSign : this.$ekmtcCommon.isNotEmpty(TH.vesselInfo) ? TH.vesselInfo.clsgNo : ''
        const bkgDocPic = TH.$ekmtcCommon.isNotEmpty(TH.parentInfo.bkgDocPic) ? TH.parentInfo.bkgDocPic : ''
        //this.cyCut = bkgDocCls // CY CUT = 서류마감일
        //const apoTcnt = TH.$ekmtcCommon.isNotEmpty(TH.parentInfo.apoTcnt) ? TH.parentInfo.apoTcnt : this.$ekmtcCommon.isNotEmpty(TH.vesselInfo) ? TH.vesselInfo.apoTcnt : ''
        const apoTcnt = TH.$ekmtcCommon.isNotEmpty(TH.parentInfo.apoTcnt) ? TH.parentInfo.apoTcnt : this.$ekmtcCommon.isNotEmpty(TH.vesselInfo) ? TH.vesselInfo.apoTcnt : ''
        if (TH.$ekmtcCommon.isNotEmpty(TH.valueFromICC)) {
          TH.valueFromICC[0].val = this.cyOpen // CY Open
          TH.valueFromICC[7].val = cgoPic // 반입지/담당자
          TH.valueFromICC[8].val = bkgDocCls // 서류마감
          TH.valueFromICC[9].val = cllPic // bkgDocPic // PIC of Document Closing > 모선마감 담당자?
          TH.valueFromICC[10].val = vgmDocCls // VGM 마감
          TH.valueFromICC[11].val = srPic // S/R 마감 담당자
          TH.valueFromICC[12].val = bkgMfCls // EDI 신고마감
          TH.valueFromICC[13].val = ediPic // 세관/위험물신고 담당자
          TH.valueFromICC[14].val = bkgCgoCls // CY 반입마감
          TH.valueFromICC[15].val = cfsCls // CFS 반입마감
          if (TH.parentInfo.bound !== undefined) {
            if (TH.parentInfo.bound === 'I' && TH.parentInfo.polTrmlCd2 !== undefined) {
              TH.valueFromICC[16].val = TH.parentInfo.polTrmlCd2 // Terminal
            } else {
              TH.valueFromICC[16].val = TH.parentInfo.polTml // Terminal
            }
          } else {
            TH.valueFromICC[16].val = TH.parentInfo.polTml // Terminal
          }
          if (TH.vesselDetail.trmlVslCd !== undefined) {
            let trmlInfo = TH.vesselDetail.trmlVslCd
            if (TH.$ekmtcCommon.isNotEmpty(TH.vesselDetail.trmlVoyNo)) {
              trmlInfo += '/' + TH.vesselDetail.trmlVoyNo
            }
            TH.valueFromICC[17].val = trmlInfo // Terminal VSL/VOY
          }
          TH.valueFromICC[18].val = mrnNo + (TH.$ekmtcCommon.isNotEmpty(apoTcnt) ? (' / ' + apoTcnt) : '')// MRN+입항횟수
          TH.valueFromICC[19].val = callSign // Call Sign
          TH.valueFromICC[20].val = this.cyCut // CY CUT
        }
        TH.initPage()
      })
    },
    async initPage () {
      const params = {
        ctrCd: this.parentInfo.polCtrCd,
        portCd: this.parentInfo.polPortCd,
        trmlCd: this.parentInfo.polTrmlCd,
        rteCd: this.parentInfo.rteCd,
        optrCd: this.vesselDetail.slotExchCstNo,
        menuCd: this.parentInfo.menuCd,
        lang: localStorage.getItem('service_lang') === 'KOR' ? 'KO' : 'EN'
      }
      if (this.$ekmtcCommon.isEmpty(this.parentInfo.polPortCd)) {
        return
      }
      // this.closeInfoData = await vslScheduleInfo.getCloseInfoList(params)
      await vslScheduleInfo.getCloseInfoList(params).then(res => {
        this.closeInfoList = res.data.closeInfoList
        this.closeInfoListReal = []
        let isEirDt = false // EIR Opening/EIR Closure 여부
        // this.closeInfoListReal2 = []
        this.isShowPolTrmlInfo = 'Y' // 반입지/담당자를 보여줄지 여부
        if (this.closeInfoList.length > 0) {
          for (let vo of this.closeInfoList) {
            if (vo.closInfRmk === 'ICC') {
              for (let item of this.valueFromICC) {
                if (vo.closInfCd === item.cd && vo.closInfNm === item.cdNm) {
                  vo.closInfVar = item.val
                }
              }
            }
            // CY OPEN
            if (vo.closInfCd === '01') {
              vo.closInfVar = this.cyOpen
            }
            // CY CUT
            if (vo.closInfCd === '21') {
              vo.closInfVar = this.cyCut
            }

            // EIR Opening/EIR Closure
            if (vo.closInfCd === '23' || vo.closInfCd === '24') {
              isEirDt = true
            }

            // Gete-in info(빈컨테이너 픽업장소)가 있는 경우 반입지/담당자를 보여주지 않는다.
            // if (vo.closInfCd === '04') {
            //   this.isShowPolTrmlInfo = 'N'
            // }
          }
        }

        // EIR Opening/EIR Closure 항목이 있는 경우 내역 조회
        console.log(`>>>>> isEirDt : ${isEirDt}`)
        if (isEirDt) {
          const eirParams = {
            vslCd: this.parentInfo.vslCd,
            voyNo: this.parentInfo.voyNo,
            polPortCd: this.parentInfo.polPortCd,
            bound: this.parentInfo.bound
          }
          vslScheduleInfo.getCloseInfoEirDt(eirParams).then(res => {
            if (res.data.closeInfoEirDt) {
              for (let vo of this.closeInfoList) {
                if (vo.closInfCd === '23') { // EIR Opening
                  if (this.$ekmtcCommon.isNotEmpty(res.data.closeInfoEirDt.eirOpeningDttm)) {
                    //vo.closInfVar = this.$ekmtcCommon.changeDatePattern(res.data.closeInfoEirDt.eirOpeningDttm, '.')
                    vo.closInfVar = this.changeDateTime2Display(res.data.closeInfoEirDt.eirOpeningDttm, this.auth.serviceLang)
                  }
                } else if (vo.closInfCd === '24') { // EIR Closure
                  if (this.$ekmtcCommon.isNotEmpty(res.data.closeInfoEirDt.eirClosureDttm)) {
                    //vo.closInfVar = this.$ekmtcCommon.changeDatePattern(res.data.closeInfoEirDt.eirClosureDttm, '.')
                    vo.closInfVar = this.changeDateTime2Display(res.data.closeInfoEirDt.eirClosureDttm, this.auth.serviceLang)
                  }
                }
              }
            }

            this.setCloseInfoListReal2() // 화면 출력 데이터 생성
          })
        } else {
          this.setCloseInfoListReal2() // 화면 출력 데이터 생성
        }
      })
    },
    setCloseInfoListReal2 () {
      // if (this.closeInfoList.length > 0) {
      //   for (let item of this.valueFromICC) {
      //     if (item.rmk === 'INPUT') {
      //       for (let vo of this.closeInfoList) {
      //         if (item.cd === vo.closInfCd) {
      //           item.val = vo.closInfVar
      //           item.rowCd = vo.rowCd
      //           if (item.val !== undefined && item.val !== '') {
      //             this.closeInfoListReal.push(item)
      //           }
      //         }
      //       }
      //     } else {
      //       // for (let vo of this.closeInfoList) {
      //         if (item.val !== undefined && item.val !== '') {
      //           // if (item.cd === vo.closInfCd) {
      //             item.rowCd = '02'
      //             this.closeInfoListReal.push(item)
      //           // }
      //         }
      //       // }
      //     }
      //   }
      // } else {
      //   for (let item of this.valueFromICC) {
      //     if (item.rmk === 'ICC') {
      //       if (item.val !== undefined && item.val !== '') {
      //         item.rowCd = '02'
      //         this.closeInfoListReal.push(item)
      //       }
      //     }
      //   }
      // }
      if (this.closeInfoList.length > 0) {
        // this.setCloseInofData(this.closeInfoListReal)
        this.setCloseInofData(this.closeInfoList)
      } else {
        this.closeInfoListReal2 = []
      }
    },
    setCloseInofData (list) {
      this.closeInfoListReal2 = []
      for (let i = 0; i < list.length; i++) {
        let showVo = {}
        const closInfNm = list[i].closInfNm || ''
        const closInfVar = list[i].closInfVar || ''
        const closInfCd = list[i].closInfCd || ''
        showVo.aCdNm = closInfNm.replace(/\n/gi, '<br>')
        showVo.aVal = closInfVar.replace(/\n/gi, '<br>')

        // console.log('closInfCd @@@@ ', closInfCd)
        if ((closInfCd === '10' || closInfCd === '14') && (this.parentInfo.polPortCd === 'DSN' || this.parentInfo.polPortCd === 'KPO' || this.parentInfo.polPortCd === 'KAN')) {
          const plcInfo = this.plcFnc(this.parentInfo.polPortCd)
          let aVal = `${plcInfo.usrNm} ${plcInfo.usrPhone} ${plcInfo.usrEmail}`
          showVo.aVal = aVal.replace(/\n/gi, '<br>')
        }

        if (list[i].rowCd === '02') {
          i++
          if (list.length !== i) {
            if (list[i].rowCd === '02') {
              const closInfNmB = list[i].closInfNm || ''
              const closInfVarB = list[i].closInfVar || ''
              showVo.bCdNm = closInfNmB.replace(/\n/gi, '<br>')
              showVo.bVal = closInfVarB.replace(/\n/gi, '<br>')
            } else {
              i--
            }
          }
        }
        // showVo.aCdNm = list[i].cdNm
        // showVo.aVal = list[i].val
        // if (list[i].rowCd === '02') {
        //   i++
        //   if (list.length !== i) {
        //     if (list[i].rowCd === '02') {
        //       showVo.bCdNm = list[i].cdNm
        //       showVo.bVal = list[i].val
        //     } else {
        //       i--
        //     }
        //   }
        // }
        this.closeInfoListReal2.push(showVo)

        console.log('closeInfoListReal2 @@@@@ ', this.closeInfoListReal2)
      }
    },
    changeDateTime2Display (strDate, lang) {
      var rtnStrDate = ''
      if (strDate === null || strDate === '') {
        rtnStrDate = ''
      } else {
        if (lang === 'ENG') {
          if (strDate.substring(4, 6) === '01') {
            rtnStrDate = strDate.substring(0, 4) + '.Jan.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '02') {
            rtnStrDate = strDate.substring(0, 4) + '.Feb.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '03') {
            rtnStrDate = strDate.substring(0, 4) + '.Mar.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '04') {
            rtnStrDate = strDate.substring(0, 4) + '.Apr.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '05') {
            rtnStrDate = strDate.substring(0, 4) + '.May.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '06') {
            rtnStrDate = strDate.substring(0, 4) + '.Jun.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '07') {
            rtnStrDate = strDate.substring(0, 4) + '.Jul.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '08') {
            rtnStrDate = strDate.substring(0, 4) + '.Aug.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '09') {
            rtnStrDate = strDate.substring(0, 4) + '.Sep.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '10') {
            rtnStrDate = strDate.substring(0, 4) + '.Oct.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '11') {
            rtnStrDate = strDate.substring(0, 4) + '.Nov.' + strDate.substring(6, 8)
          } else {
            rtnStrDate = strDate.substring(0, 4) + '.Dec.' + strDate.substring(6, 8)
          }
        } else {
          rtnStrDate = strDate.substring(0, 4) + '.' + strDate.substring(4, 6) + '.' + strDate.substring(6, 8)
        }
        rtnStrDate = rtnStrDate + ' ' + strDate.substring(8, 10) + ':' + strDate.substring(10, 12)
      }
      return rtnStrDate
    },
    plcFnc (plcCd) {
      let rtnObj = {}
      if (plcCd === 'DSN') {
        rtnObj = {
          usrNm: '대동항업(권성순)',
          usrPhone: '041-664-8645',
          usrEmail: 'daedongagent@daum.net'
        }
      } else if (plcCd === 'KPO') {
        rtnObj = {
          usrNm: '신진해운(최제이)',
          usrPhone: '054-272-5204',
          usrEmail: 'mail@shinjinpohang.co.kr'
        }
      } else if (plcCd === 'KAN') {
        rtnObj = {
          usrNm: '여진해운',
          usrPhone: '061-793-0211',
          usrEmail: 'ops@yeojin.net'
        }
      }
      return rtnObj
    }
  }
}
</script>
